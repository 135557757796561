import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../layout/layout"
import styled from 'styled-components'

import CodingBrowser from '../images/coding-browser.png'

const CaseTemplate = ({ data }) => {
    const info = data.wordpress.case;
    const cases = data.wordpress.cases.edges;
    return (
        <Layout headerType='dark' pageTitle="Case">
            <Style>
                {/* Main Banner */}
                <div className="container-fluid main-banner">
                    <div className="container">
                        <h1>{info.title}</h1>
                        <p>{info.cases.description}</p>
                        <div className="main-image-wrapper">
                            {info.cases.imageMainBanner && <Img fluid={info.cases.imageMainBanner.imageFile.childImageSharp.fluid} />}
                        </div>
                    </div>
                </div>
                {/* About the client */}
                <div className="container-fluid about">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-sm-12 col-md-6 about-info">
                                <h2>About the client</h2>
                                {info.cases.aboutTheClient && <div dangerouslySetInnerHTML={{ __html: info.cases.aboutTheClient }} />}
                            </div>
                            <div className="col-0 col-md-3"></div>
                            <div className="col-12 col-sm-12 col-md-3">
                                <div className="row">
                                    <div className="col">
                                        <p className="about-title">Client</p>
                                        {info.cases.company && <p className="about-detail">{info.cases.company}</p>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <p className="about-title">Scope of work</p>
                                        <ul className="ul-no-class">
                                            {info.cases.scopeOfWork && info.cases.scopeOfWork.map(item => (
                                                <li>{item.item}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <p className="about-title">Website</p>
                                        {info.cases.website &&
                                            <a href={info.cases.website} target="_blank" rel="noopener noreferrer">
                                                <p>{info.cases.websiteText}</p>
                                            </a>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* The Challenge & Solution*/}
                <div className="container challenge-solution">
                    <div className={`row ${info.cases.imageTheChallenge ? 'challenge-image' : 'challenge-no-image'}`}>
                        <div className="col">
                            {info.cases.imageTheChallenge && <Img fluid={info.cases.imageTheChallenge.imageFile.childImageSharp.fluid} />}
                        </div>
                    </div>
                    <div className="row cs-content">
                        <div className="col-12 col-sm-12 col-md-6">
                            <h2>The challenge</h2>
                            {info.cases.theChallenge && <div dangerouslySetInnerHTML={{ __html: info.cases.theChallenge }} />}
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                            <h2>The solution</h2>
                            {info.cases.theSolution && <div dangerouslySetInnerHTML={{ __html: info.cases.theSolution }} />}
                        </div>
                    </div>
                </div>
                <div className="container-fluid solution-image">
                    <div className="row">
                        {info.cases.imageTheSolution && info.cases.imageTheSolution.map(image => (
                            <div className="col-12 col-sm-6">
                                <Img fluid={image.imageFile.childImageSharp.fluid} />
                            </div>
                        ))}
                    </div>
                </div>
                {/* The Magic Quote */}
                <div className="container-fluid container-p-15 magic-quote">
                    <div className="row">
                        <div className="col">
                            {info.cases.magicQuote && <p>{info.cases.magicQuote}</p>}
                        </div>
                    </div>
                </div>
                {/* The Result */}
                <div className="container container-p result">
                    <div className="row">
                        <div className="col">
                            <h2>The result</h2>
                            {info.cases.theResult && <div dangerouslySetInnerHTML={{ __html: info.cases.theResult }} />}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            {info.cases.imageTheResult && info.cases.imageTheResult.map(image => (
                                <Img fluid={image.imageFile.childImageSharp.fluid} />
                            ))}
                        </div>
                    </div>
                </div>
                {/* The Technologies */}
                <div className="container-fluid container-p-15 technologies">
                    <div className="row align-items-center">
                        <div className="col-12 col-sm-12 col-lg-6">
                            <DevAnimation>
                                <div className="anim anim--typing anim--typing-code playing">
                                    <div className="bg">
                                    <div className="typewriter">
                                        <p className="d-none d-lg-inline"><span class="protocol">GET</span> <span class="value">.../iqplus/orders/</span> <span className="protocol d-sm_none">HTTP/1.1</span></p>
                                        <p className="d-none d-lg-inline">&nbsp;</p>
                                        <p>New orders details:</p>
                                        <p className="d-450">{"{"}</p>
                                        <p className="d-991"><span className="key">"id":</span> <span class="value">"ord_p7ZAMo1xwNJ4xX"</span>,</p>
                                        <p className="d-991"><span className="key">"customer_reference":</span> <span class="value">"TSTNC-21"</span>,</p>
                                        <p className="d-450"><span className="key">"currency":</span> <span class="value">"Euro"</span>,</p>
                                        <p><span className="key">"order_value":</span> <span class="value">100</span></p>
                                        <p><span className="key">"customer":</span> {"{"}</p>
                                        <p className="ml-4 typing typing-email"><span class="key">"email":</span> <span class="value">"hello@customer.io"</span>,</p>
                                        <p className="ml-4 typing typing-name"><span class="key">"name":</span> <span class="value">"John Doe"</span></p>
                                        <p className="ml-4 typing typing-run"><span>Run Program</span></p>
                                    </div>
                                    </div>
                                </div>
                            </DevAnimation>
                        </div>
                        <div className="col-12 col-sm-12 col-lg-6">
                            <div className="technologies-content">
                                <h2>We are always learning and using the latest technologies on our projects if possible.</h2>
                                {info.cases.technologies && <div dangerouslySetInnerHTML={{ __html: info.cases.technologies }} />}
                            </div>
                        </div>
                    </div>
                </div>
                {/* Other cases */}
                <div className="container container-p related-projects">
                    <div className="row">
                        <div className="col">
                            <h2>Time to check out other projects?</h2>
                        </div>
                    </div>
                    <div className="row">
                        {cases.map(theCase => (
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                <a href={`..${theCase.node.uri}`} className="related-project-image">
                                    {theCase.node.featuredImage && <Img fluid={theCase.node.featuredImage.imageFile.childImageSharp.fluid} />}
                                    {theCase.node.cases.company && <span className="thecase-company">{theCase.node.cases.company}</span>}
                                    {theCase.node.cases.mainsolution && <span className="thecase-solution">{theCase.node.cases.mainsolution}</span>}
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </Style>
        </Layout>
    )
}
export default CaseTemplate;


export const query = graphql`
    query ($id: ID!) {
        wordpress {
            case(id: $id) {
                id
                title
                slug
                cases {
                    company
                    mainsolution
                    description
                    fieldGroupName
                    title
                    aboutTheClient
                    website
                    websiteText
                    theChallenge
                    theSolution
                    theResult
                    magicQuote
                    technologies
                    scopeOfWork {
                        item
                      }
                    imageMainBanner {
                        sourceUrl
                        imageFile {
                            childImageSharp {
                                fluid{
                                    ...GatsbyImageSharpFluid_noBase64
                                }
                            }
                        }
                    }
                    imageTheChallenge {
                        sourceUrl
                        imageFile {
                            childImageSharp {
                                fluid{
                                    ...GatsbyImageSharpFluid_noBase64
                                }
                            }
                        }
                    }
                    imageTheSolution {
                        sourceUrl
                        imageFile {
                            childImageSharp {
                                fluid{
                                    ...GatsbyImageSharpFluid_noBase64
                                }
                            }
                        }
                    }
                    imageTheResult {
                        sourceUrl
                        imageFile {
                            childImageSharp {
                                fluid{
                                    ...GatsbyImageSharpFluid_noBase64
                                }
                            }
                        }
                    }
                }
            }
            cases {
                edges {
                    node {
                        id
                        uri
                        title
                        cases {
                            company
                            mainsolution
                        }
                        featuredImage {
                            sourceUrl
                            imageFile {
                                childImageSharp {
                                    fluid{
                                        ...GatsbyImageSharpFluid_noBase64
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

const Style = styled.div`
    img {
        border-radius: 15px;
    }
    .main-banner {
        background: ${({ theme }) => `-webkit-linear-gradient(top, ${theme.secondaryLight}, ${theme.secondaryDark} 70%, ${theme.secondaryDark} 30%, ${theme.secondaryDark})` };
        color: white;
        padding-top: 5%;
        padding-bottom: 10%;
        text-align: center;
        .main-image-wrapper {
            padding-top: 5%;
        }
        p {
            max-width: 700px;
            margin: 0 auto;
        }
    }
    .about {
        background: ${({ theme }) => theme.secondaryDark};
        color: white;
        padding-top: 5%;
        padding-bottom: 15%;
        a {
            color: ${({ theme }) => theme.primaryLight};
        }
        &-info {
            p {
                line-height: 2;
            }
        }
        &-title {
            margin-bottom: 0.5rem;
        }
        &-detail {
            color: ${({ theme }) => theme.primaryLight};
        }
        ul {
            color: ${({ theme }) => theme.primaryLight};
        }
    }
    .challenge-image {
        margin-top: 10%;
        margin-bottom: 10%;

    }
    .challenge-no-image {
        margin-top: 10%;
    }
    .cs-content {
        padding-top: 0px;
        padding-bottom: 5%;
        p {
            max-width: 450px;
        }
        @media only screen and (max-width: 767px) {
            p {
                max-width: 100%;
            }
        }
    }
    .solution-image {
        padding-bottom: 10%;
        .gatsby-image-wrapper {
            margin-bottom: 30px;
        }
    }
    .magic-quote {
        background: ${({ theme }) => theme.secondaryLight};
        p {
            max-width: 700px;
            margin: 0 auto;
            font-style: oblique;
            line-height: 2;
            font-size: 1.5rem;
            color: white;
        }
    }
    .result {
        .gatsby-image-wrapper {
            margin-bottom: 2rem;
        }
        iframe {
            width: 100%;
            height: 500px;
            border-radius: 15px;
        }
    }
    .technologies {
        background: black;
        color: white;
        &-content {
            max-width: 600px;
        }
    }
    .related-projects {
        h2 {
            margin-bottom: 3rem;
        }
    }
    .related-project-image {
        .gatsby-image-wrapper {
            height: 400px;
            border-radius: 15px;
            margin-bottom: 30px;
        }
        img {
            filter: contrast(150%) brightness(75%);
        }
        span {
            position: absolute;
            font-size: 1rem;
            left: 30px;
            font-weight: 500;
            color: white;
        }
        .thecase-company {
            bottom: 90px;
        }
        .thecase-solution {
            bottom: 60px;
        }
        &:hover {
            .gatsby-image-wrapper {
                transform: scale(1.02);
                transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
            }
            img {
                filter: contrast(150%) brightness(50%);
            }
        }
    }
`

const DevAnimation = styled.div`

    max-width: 600px;
    margin: 0 auto;

  .anim--typing-code {
    width: 425px;
  }
  .anim {
    position: relative;
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    flex-shrink: inherit;
    justify-content: center;
  }

  .anim--typing-code .bg {
    background-image: url(${CodingBrowser});
    height: 100%;
  }

  .anim .bg {
    background-size: 100%;
    background-position: center;
    width: 100%;
  }

  .anim .bg {
    background-repeat: no-repeat;
  }

  .anim--typing-code .typewriter {
    position: absolute;
    top: 60px;
    left: 110px;
    width: 89%;
    height: 200px;
    padding: 0 1rem;
    color: #999;
    font-family: 'Courier New',sans-serif;
  }

  .anim--typing-code .typewriter p {
    letter-spacing: .025em;
    font-size: .65em;
    margin: 0;
    line-height: 1.1rem;
    font-weight: 500;
  }
  .section p:not(.bold) {
    font-weight: 300;
  }
  .d-lg-inline {
    display: inline;
  }

  .anim--typing-code .typewriter .protocol {
    color: #f9bd66;
  }


  .ml-4, .mx-4 {
    margin-left: 1.5rem!important;
  }

  .anim--typing-code .typewriter .key {
    color: #5873E5;
  }

  .anim--typing-code .typewriter .value {
    color: #3DCEF4;
  }

  .anim.anim--typing.playing .typing-email {
    animation:
        typing-email 5s steps(40, end) infinite,
        blink-caret-none .75s step-end infinite;
  }
  .anim.anim--typing.playing .typing-name {
    animation:
        typing-name 5s steps(40, end) infinite,
        blink-caret .75s step-end infinite;
    animation-delay: 1s;
  }
  .anim.anim--typing.playing .typing-run {
    animation:
        typing-run 5s steps(40, end) infinite,
        blink-caret-none .75s step-end infinite;
    animation-delay: 2s;
    margin-top: 45px;
    color: #5873E5;
  }
  .anim--typing-code .typewriter .typing {
    overflow: hidden;
    border-right: .2em solid #999;
    white-space: nowrap;
    margin: 0 auto;
  }

  /* Typing effect */
  @keyframes typing-email {
    0% {width: 0;}
    20% {width: 45%;}
    100% {width: 45%;}
  }
  @keyframes typing-name {
    0% {width: 0;}
    20% {width: 28%;}
    100% {width: 28%;}
  }
  @keyframes typing-run {
    0% {width: 0;}
    20% {width: 19%;}
    100% {width: 19%;}
  }
  /* Cursor */
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: orange; }
  }
  @keyframes blink-caret-none {
    from, to { border-color: transparent }
    50% { border-color: transparent; }
  }

  @media only screen and (max-width: 1399px) and (min-width: 1200px) {
    .anim {width: 500px;}
    .d-none {display:none!important;}
    .d-lg-inline {display: none;}
    .anim--typing-code .typewriter {top: 90px;}
    .anim.anim--typing.playing .typing-run {margin-top: 40px;}
    @keyframes typing-email {
      0% {width: 0;}
      20% {width: 40%;}
      100% {width: 40%;}
    }
    @keyframes typing-name {
      0% {width: 0;}
      20% {width: 25%;}
      100% {width: 25%;}
    }
  }
  @media only screen and (max-width: 1199px) {
    .anim {width: 400px;}
    .d-none {display:none!important;}
    .d-lg-inline {display: none;}
    .d-991 {display: none;}
    .anim--typing-code .typewriter {top: 110px; left:85px}
    .anim.anim--typing.playing .typing-run {margin-top: 35px;}
    @keyframes typing-email {
      0% {width: 0;}
      20% {width: 47%;}
      100% {width: 47%;}
    }
    @keyframes typing-name {
      0% {width: 0;}
      20% {width: 32%;}
      100% {width: 32%;}
    }
    @keyframes typing-run {
      0% {width: 0;}
      20% {width: 25%;}
      100% {width: 25%;}
    }
  }
  @media only screen and (max-width: 991px) {
    .anim {margin: 0 auto;}
  }

  @media only screen and (max-width: 450px) {
    .anim {width: 300px;}
    .d-450 {display:none;}
    .anim--typing-code .typewriter {top:139px;left:55px}
    .anim.anim--typing.playing .typing-run {margin-top: 22px;}
    @keyframes typing-email {
      0% {width: 0;}
      20% {width: 65%;}
      100% {width: 65%;}
    }
    @keyframes typing-name {
      0% {width: 0;}
      20% {width: 45%;}
      100% {width: 45%;}
    }
    @keyframes typing-run {
      0% {width: 0;}
      20% {width: 35%;}
      100% {width: 35%;}
    }
  }

`;
